<template>
  <section class="base-page">
    <ProductPageHeader />
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="addRow"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'nameCn'">{{
              scope.row.name.cn
            }}</span>
            <span v-else-if="column.prop === 'nameEn'">{{
              scope.row.name.en
            }}</span>
            <span v-else-if="column.prop === 'introduction'">{{
              scope.row.introduction ? scope.row.introduction.cn : "/"
            }}</span>
            <span v-else-if="column.prop === 'description'">{{
              scope.row.description ? scope.row.description.cn : "/"
            }}</span>
            <span v-else-if="column.prop === 'storyDescription'">{{
              scope.row.storyDescription ? scope.row.storyDescription.cn : "/"
            }}</span>
            <span v-else-if="column.prop === 'backgroundColor'">
              <span
                :style="{
                  width: '100%',
                  height: '50px',
                  display: 'block',
                  'background-color': scope.row.backgroundColor,
                }"
              ></span>
            </span>

            <el-image
              v-else-if="column.prop === 'posterImageUrl'"
              style="width: 100%; height: 66px;"
              :src="scope.row.posterImageUrl"
              :preview-src-list="posterImages"
            >
            </el-image>
            <el-image
              v-else-if="column.prop === 'coverImageUrl'"
              style="width: 100%; height: 66px;"
              :src="scope.row.coverImageUrl"
              :preview-src-list="coverImages"
            >
            </el-image>
            <span v-else-if="column.prop === 'themeMedias'">
              {{ scope.row.themeMedias.length }} 个
            </span>
            <span v-else-if="column.prop === 'storyMedias'">
              {{ scope.row.storyMedias.length }} 个
            </span>

            <span v-else-if="column.prop === 'albums'">
              <el-popover
                placement="right"
                width="450"
                trigger="click"
                v-if="scope.row.albums.length > 0"
              >
                <el-carousel height="300px">
                  <el-carousel-item
                    v-for="item in scope.row.albums"
                    :key="item.url"
                    :title="`${item.nameCn} | ${item.nameEn}`"
                  >
                    <img :src="item.url" alt="画册图片" class="preview-img" />
                  </el-carousel-item>
                </el-carousel>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.albums.length }}张</span
                >
              </el-popover>
              <span v-else>暂无图片</span>
            </span>
            <span v-else-if="column.prop === 'images'">
              <el-popover
                placement="right"
                width="450"
                trigger="click"
                v-if="scope.row.images.length > 0"
              >
                <el-carousel height="300px">
                  <el-carousel-item
                    v-for="item in scope.row.images"
                    :key="item.url"
                    :title="`${item.nameCn} | ${item.nameEn}`"
                  >
                    <img
                      :src="item.url"
                      alt="资料图片"
                      class="preview-img contain"
                    />
                  </el-carousel-item>
                </el-carousel>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.images.length }}张</span
                >
              </el-popover>
              <span v-else>暂无图片</span>
            </span>
            <span v-else-if="column.prop === 'files'">
              <el-popover
                placement="right"
                width="300"
                trigger="click"
                v-if="scope.row.files.length > 0"
              >
                <ul class="series-file-list">
                  <li
                    class="list-item"
                    v-for="item in scope.row.files"
                    :key="item.url"
                    :title="`${item.nameCn} | ${item.nameEn}`"
                  >
                    <a
                      :href="item.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      :download="item.nameEn"
                    >
                      <p>中文: {{ item.name.cn }}</p>
                      <p>英文: {{ item.name.en }}</p>
                    </a>
                  </li>
                </ul>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.files.length }}个文件</span
                >
              </el-popover>
              <span v-else>暂无文件</span>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.canUpdate"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >编辑</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="scope.row.canDisable"
                  :class="[scope.row.disabled ? 'text-success' : 'text-danger']"
                  @click.native.prevent="
                    toggleDisableRow(scope.row, scope.$index)
                  "
                >
                  {{ scope.row.disabled ? "启用" : "禁用" }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          >批量启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          >批量禁用 {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <Form
      :show="formDialog"
      :sid="currentId"
      @cancel="onFormCancel"
      @success="onFormSuccess"
      @close="onFormClose"
    />

    <el-drawer
      title="新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      direction="rtl"
      size="50%"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-drawer>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetSeriesList,
  ToogleDisableByIds,
  GetDisableCheck,
  GetUpdateCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import ProductPageHeader from "@/components/ProductPageHeader";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  name: "ProductSeriesList",
  components: {
    BasicTable,
    Form,
    FilterSection,
    MultipleForm,
    ProductPageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
    };
  },
  computed: {
    posterImages() {
      return this.tableData.map((item) => item.posterImageUrl);
    },
    coverImages() {
      return this.tableData.map((item) => item.coverImageUrl);
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "nameCn",
            label: "中文名",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "nameEn",
            label: "英文名",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "introduction",
            label: "描述标题",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "description",
            label: "描述",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "storyDescription",
            label: "系列介绍",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "backgroundColor",
            label: "代表色",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "coverImageUrl",
            label: "封面图",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "posterImageUrl",
            label: "海报图",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "themeMedias",
            label: "Banner媒体",
            width: 150,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "storyMedias",
            label: "系列媒体",
            width: 150,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "albums",
            label: "图册",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "images",
            label: "轮播图",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "files",
            label: "附件",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "id",
            label: "ID",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            minWidth: 150,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetSeriesList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
            };
          });
          this.onUpdateCheck();
          this.onDisableCheck();
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.currentId = null;
      this.formDialog = true;
    },
    editRow(row) {
      if (!row.canUpdate) {
        this.$message.warning("没有权限执行此操作!");
        return false;
      }
      this.currentId = row.id;
      this.formDialog = true;
    },
    async onUpdateCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetUpdateCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canUpdate = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canUpdate = true;
          return item;
        });
      }
    },
    async onDisableCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetDisableCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canDisable = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canDisable = true;
          return item;
        });
      }
    },
    onFormClose() {
      this.formDialog = false;
      this.currentId = null;
    },
    onFormCancel() {
      this.formDialog = false;
      this.currentId = null;
      this.multipleFormDialog = false;
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "nameCn",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError("操作请求错误",err);
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row, index) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.$set(this.tableData, index, row);
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.contain {
      object-fit: contain;
    }
  }
}
</style>
<style lang="scss">
.series-file-list {
  width: 100%;
  .list-item {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 0;
    color: #4dbeff;
    cursor: pointer;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    a {
      color: inherit;
    }
  }
}
</style>
