<template>
  <el-drawer
    :title="currentId ? '编辑' : '新增'"
    :visible.sync="showDrawer"
    append-to-body
    direction="rtl"
    size="800px"
    @close="onDrawerClose"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="edit-form"
      label-position="top"
      v-loading="loading"
      :disabled="submitting"
    >
      <LocaleFormTab @change="onLocaleChange">
        <!-- 中文部分 -->
        <template #cn>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item label="中文名称" prop="name.cn">
                <el-input
                  v-model="form.name.cn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="14">
              <el-form-item label="中文描述标题" prop="introduction.cn">
                <el-input
                  v-model="form.introduction.cn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24">
              <el-form-item label="中文描述" prop="description.cn">
                <el-input
                  v-model="form.description.cn"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24">
              <el-form-item label="中文系列介绍" prop="storyDescription.cn">
                <el-input
                  v-model="form.storyDescription.cn"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="14">
              <el-form-item
                label="海报中文文标语(slogan)"
                prop="posterSlogan.cn"
              >
                <el-input
                  v-model="form.posterSlogan.cn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <!-- 英文部分 -->
        <template #en>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item label="英文名称" prop="name.cn">
                <el-input
                  v-model="form.name.en"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="14">
              <el-form-item label="英文描述标题" prop="introduction.en">
                <el-input
                  v-model="form.introduction.en"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24">
              <el-form-item label="英文描述" prop="description.en">
                <el-input
                  v-model="form.description.en"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24">
              <el-form-item label="英文系列介绍" prop="storyDescription.en">
                <el-input
                  v-model="form.storyDescription.en"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="14">
              <el-form-item label="海报英文标语(slogan)" prop="posterSlogan.en">
                <el-input
                  v-model="form.posterSlogan.en"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </LocaleFormTab>

      <el-divider content-position="left">以下为通用部分</el-divider>

      <el-row :gutter="10">
        <el-col :sm="24" :md="8">
          <el-form-item label="次序(数值越大越靠前)" prop="seq">
            <el-input-number
              v-model="form.seq"
              label="请输入"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item label="编码" prop="code">
            <el-input
              v-model="form.code"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item label="代表色" prop="backgroundColor">
            <el-color-picker
              v-model="form.backgroundColor"
              show-alpha
              :predefine="predefineColors"
            >
            </el-color-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :sm="24" :md="12">
          <el-form-item label="封面图片" prop="coverImageUrl">
            <p class="form-tips">
              提示：该图会用在系列列表的封面图，图片宽除于高的比值保持在1.5，建议900*600同等比例的图，大小保持在1MB左右，越高清越好
            </p>
            <SingleImgUpload
              class="cover-img"
              :size="2"
              v-model="form.coverImageUrl"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-form-item label="海报图" prop="posterImageUrl">
            <p class="form-tips">
              提示：用作海报宣传图，建议宽高【1125x2436】等同比例（宽高比0.46）尺寸的图片,建议大小保持在1.5MB以内
            </p>
            <SingleImgUpload
              :size="2"
              class="poster-img"
              v-model="form.posterImageUrl"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <p class="form-tips">
        Banner数据集合：在系列详情页中的banner图或者是视频，或者混合媒体数据
      </p>

      <el-form-item label="Banner数据集合(显示顺序从左到右)" prop="themeMedias">
        <DraggbleSort
          syncSortKey
          v-model="form.themeMedias"
          class="medias-list-wrap"
        >
          <template v-slot:default="slotProps">
            <ProductImgUpload
              v-if="slotProps.item.typeId == 'IMAGE'"
              class="medias-item"
              v-model="slotProps.item.url"
              @remove="removeThemeMedias(slotProps.index)"
            />
            <div
              v-if="slotProps.item.typeId == 'VIDEO'"
              class="video-medias-item"
            >
              <ProductVideoItem
                :showDeleteIcon="false"
                class="medias-item"
                :size="20480"
                v-model="slotProps.item.url"
              />
              <ProductImgUpload
                class="medias-item"
                tips="选择图片"
                v-model="slotProps.item.coverImageUrl"
                @remove="clearThemeVideoCover(slotProps.index)"
              />
              <i
                class="el-icon-delete remove-video-icon"
                title="点击删除"
                @click="removeThemeMedias(slotProps.index)"
              ></i>
            </div>
          </template>
        </DraggbleSort>
        <div class="add-madias-item">
          <el-button-group>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-picture-outline"
              @click="addThemeMedias('IMAGE')"
              >添加图片</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addThemeMedias('VIDEO')"
              >添加视频<i class="el-icon-video-camera-solid el-icon--right"></i
            ></el-button>
          </el-button-group>
        </div>
        <!-- <ProductMediaUpload /> -->
      </el-form-item>

      <p class="form-tips">
        系列介绍媒体数据集：用于放介绍该系列的图片和视频的集合数据
      </p>
      <el-form-item
        label="系列介绍媒体数据集(显示顺序从左到右)"
        prop="themeMedias"
      >
        <DraggbleSort
          syncSortKey
          v-model="form.storyMedias"
          class="medias-list-wrap"
        >
          <template v-slot:default="slotProps">
            <ProductImgUpload
              v-if="slotProps.item.typeId == 'IMAGE'"
              class="medias-item"
              v-model="slotProps.item.url"
              @remove="removeStoryMedias(slotProps.index)"
            />
            <div
              v-if="slotProps.item.typeId == 'VIDEO'"
              class="video-medias-item"
            >
              <ProductVideoItem
                :showDeleteIcon="false"
                class="medias-item"
                :size="20480"
                v-model="slotProps.item.url"
              />
              <ProductImgUpload
                class="medias-item"
                tips="选择图片"
                v-model="slotProps.item.coverImageUrl"
                @remove="clearStoryVideoCover(slotProps.index)"
              />
              <i
                class="el-icon-delete remove-video-icon"
                title="点击删除"
                @click="removeStoryMedias(slotProps.index)"
              ></i>
            </div>
          </template>
        </DraggbleSort>
        <div class="add-madias-item">
          <el-button-group>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-picture-outline"
              @click="addStoryMedias('IMAGE')"
              >添加介绍图片</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addStoryMedias('VIDEO')"
              >添加介绍视频<i
                class="el-icon-video-camera-solid el-icon--right"
              ></i
            ></el-button>
          </el-button-group>
        </div>
        <!-- <ProductMediaUpload /> -->
      </el-form-item>

      <el-form-item label="图册" prop="albums">
        <el-upload
          :disabled="isAlbumsLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleAlbumsBeforeUpload"
          :on-success="handleAlbumsSuccess"
          :on-error="handleAlbumsError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isAlbumsLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isAlbumsLoading"
            >{{ isAlbumsLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            <p>限制文件类型为jpeg、jpg、png</p>
            <p>
              图册的图片尺寸不限制，但是为了客户端显示整齐，建议尽量都保持同等比例。
            </p>
            <p>图片大小尽量保持在1-2MB以内,无严格限制,图片过大会导致显示缓慢</p>
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.albums" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="slotProps.item.url"
                  :preview-src-list="form.albums.map((item) => item.url)"
                >
                </el-image>
                <!-- <img :src="slotProps.item.url" /> -->
              </div>
              <div class="file-content">
                <el-input
                  size="small"
                  v-model="slotProps.item.name.cn"
                  placeholder="请输入中文名称"
                  ><template slot="prepend">中文</template></el-input
                >
                <el-input
                  size="small"
                  v-model="slotProps.item.name.en"
                  placeholder="请输入英文名称"
                  ><template slot="prepend">英文</template></el-input
                >
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveAlbums(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>
      <el-form-item label="可下载附件" prop="files">
        <el-upload
          :disabled="isFileLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleFilesBeforeUpload"
          :on-success="handleFilesSuccess"
          :on-error="handleFilesError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isFileLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isFileLoading"
            >{{ isFileLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            文件类型不限制，单个文件最大建议保持在15MB以内
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.files" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <a
                  :href="slotProps.item.url"
                  target="_blank"
                  download="true"
                  rel="noopener noreferrer"
                  title="点击预览"
                >
                  <i class="el-icon-document"></i>
                </a>
              </div>
              <div class="file-content">
                <el-input
                  size="small"
                  v-model="slotProps.item.name.cn"
                  placeholder="请输入中文名称"
                  ><template slot="prepend">中文</template></el-input
                >
                <el-input
                  size="small"
                  v-model="slotProps.item.name.en"
                  placeholder="请输入英文名称"
                  ><template slot="prepend">英文</template></el-input
                >
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveFiles(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>

      <el-form-item label="图片合集" prop="images">
        <el-upload
          :disabled="isImagesLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleImagesBeforeUpload"
          :on-success="handleImagesSuccess"
          :on-error="handleImagesError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isImagesLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isImagesLoading"
            >{{ isImagesLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            <p>限制文件类型为jpeg、jpg、png</p>
            <p>
              图片尺寸比例建议1.2，计算方式为宽度除于高度，例如262/215，图片大小尽量保持在1MB以内
            </p>
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.images" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="slotProps.item.url"
                  :preview-src-list="form.images.map((item) => item.url)"
                >
                </el-image>
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveImages(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>

      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button @click="onResetForm('form')">重 置</el-button>
        <el-button
          v-if="currentId"
          type="warning"
          @click="submitEidtForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import { PostSeries, PutSeries, GetSeriesById } from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
import DraggbleSort from "@/components/DraggbleSort";
import LocaleFormTab from "@/components/LocaleFormTab";
import ProductVideoItem from "@/components/ProductVideoItem";
import ProductImgUpload from "@/components/ProductImgUpload";
import { buildI18nKeyValue } from "@/utils/common";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SingleImgUpload,
    DraggbleSort,
    LocaleFormTab,
    ProductVideoItem,
    ProductImgUpload,
  },
  data() {
    return {
      currentLocalName: "cn",
      currentId: null,
      loading: false,
      submitting: false,
      showDrawer: false,
      isAlbumsLoading: false,
      isFileLoading: false,
      isImagesLoading: false,
      form: {},
      rules: {
        "name.cn": [{ required: true, message: "请输入", trigger: "blur" }],
        "name.en": [{ required: true, message: "请输入", trigger: "blur" }],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization: getToken(),
      };
    },
    onUploadUrl() {
      return API_CONFIG.BASE_V1_API + "/oss/upload/cdn";
    },
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
    predefineColors() {
      return this.$store.getters.predefineColors;
    },
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = value;
        } else {
          this.currentId = null;
          this.initForm();
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.showDrawer = value;
        if (this.currentId) {
          this.getFormData();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    onLocaleChange(e) {
      let name = e.name;
      this.currentLocalName = name;
    },
    //banner数据操作
    addThemeMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.themeMedias.length,
        typeId: type,
        url: "",
      };
      this.form.themeMedias.push(item);
    },

    removeThemeMedias(index) {
      this.form.themeMedias.splice(index, 1);
    },

    clearThemeVideoCover(index) {
      this.form.themeMedias = this.form.themeMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    //系列讲解数据操作

    addStoryMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.storyMedias.length,
        typeId: type,
        url: "",
      };
      this.form.storyMedias.push(item);
    },

    removeStoryMedias(index) {
      this.form.storyMedias.splice(index, 1);
    },

    clearStoryVideoCover(index) {
      this.form.storyMedias = this.form.storyMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },

    // 画册操作
    handleAlbumsBeforeUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      this.isAlbumsLoading = true;
      return true;
    },
    handleAlbumsSuccess(response, file) {
      this.form.albums.push({
        name: {
          cn: file.name,
          en: file.name,
        },
        url: file.response ? file.response : "",
        seq: this.form.albums.length + 1,
      });
      this.isAlbumsLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
    },
    handleAlbumsError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isAlbumsLoading = false;
    },
    handleRemoveAlbums(index) {
      this.form.albums.splice(index, 1);
    },

    handleFilesBeforeUpload() {
      this.isFileLoading = true;
      return true;
    },
    handleFilesSuccess(response, file) {
      this.form.files.push({
        name: {
          cn: file.name,
          en: file.name,
        },
        url: file.response ? file.response : "",
        seq: this.form.files.length + 1,
      });
      this.isFileLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
    },
    handleFilesError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isFileLoading = false;
    },
    handleRemoveFiles(index) {
      this.form.files.splice(index, 1);
    },

    handleImagesBeforeUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      this.isImagesLoading = true;
      return true;
    },
    handleImagesSuccess(response, file) {
      this.form.images.push({
        name: {
          cn: file.name,
          en: file.name,
        },
        url: file.response ? file.response : "",
        seq: this.form.images.length + 1,
      });
      this.isImagesLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
    },
    handleImagesError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isImagesLoading = false;
    },
    handleRemoveImages(index) {
      this.form.images.splice(index, 1);
    },

    buildFormParams() {
      return this.form;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostSeries([this.buildFormParams()])
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEidtForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutSeries([
            {
              id: this.currentId,
              ...this.buildFormParams(),
            },
          ])
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.getFormData();
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetSeriesById(this.currentId)
        .then((res) => {
          this.form = this.buildFormItem();
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.form.name = buildI18nKeyValue(res.data.name);
          this.form.introduction = buildI18nKeyValue(
            res.data.introduction
          );
          this.form.description = buildI18nKeyValue(res.data.description);
          this.form.storyDescription = buildI18nKeyValue(
            res.data.storyDescription
          );
          this.form.posterSlogan = buildI18nKeyValue(
            res.data.posterSlogan
          );
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败",err);
        });
    },
    buildFormItem() {
      return {
        code: "",
        name: { ...this.localeColumns },
        introduction: { ...this.localeColumns },
        description: { ...this.localeColumns },
        storyDescription: { ...this.localeColumns },
        posterImageUrl: "",
        posterSlogan: { ...this.localeColumns },
        coverImageUrl: "",
        backgroundColor: "rgba(255, 69, 0, 0.68)",
        images: [
          // {
          //   url:"",
          //   seq:0
          // }
        ],
        albums: [
          //  {
          //   name: { ...this.localeColumns },
          //   url:"",
          //   seq:0
          // }
        ],
        files: [
          //  {
          //   name: { ...this.localeColumns },
          //   url:"",
          //   seq:0
          // }
        ],
        storyMedias: [
          //  {
          //   typeId:'',
          //   coverImageUrl:''
          //   url:"",
          //   seq:0
          // }
        ],
        themeMedias: [
          //  {
          //   typeId:'',
          //   coverImageUrl:''
          //   url:"",
          //   seq:0
          // }
        ],
        seq: 1,
      };
    },
    initForm() {
      this.form = {
        ...this.buildFormItem(),
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    onResetForm() {
      this.$confirm("确定要重置表单吗?", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.currentId) {
            this.getFormData();
          } else {
            this.initForm();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onDrawerClose() {
      this.showDrawer = false;
      this.$emit("close", this.showDrawer);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.medias-list-wrap {
  display: inline-block;
  max-width: 100%;
  ::v-deep {
    .transition-el {
      max-height: none;
    }
  }
  .medias-item {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  .video-medias-item {
    box-sizing: border-box;
    padding: 5px 0;
    margin: 5px;
    width: 280px;
    height: 100px;
    position: relative;
    padding-right: 20px;
    background-color: #edfae8;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .remove-video-icon {
      position: absolute;
      bottom: 5px;
      right: 2px;
      font-size: 16px;
      cursor: pointer;
      color: #c51515;
    }
    ::v-deep {
      .basic-upload-wrap {
        width: 150px;
        height: 90px;
      }
      .img-upload-wrap {
        width: 90px;
        height: 90px;
      }
    }
  }
}
.add-madias-item {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 30px;
  margin: 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.poster-img {
  width: 150px;
  height: 326px;
}
.edit-form {
  padding: 0 20px;
}
.form-tips {
  font-size: 12px;
  margin: 0;
  color: #989898;
}
.cover-img {
  width: 300px;
  height: 200px;
}
.file-list-wrap {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  border: 1px dashed #f1f1f1;
  background-color: #fffdfc;
  ::v-deep {
    .transition-el {
      box-sizing: border-box;
      width: 100%;
      padding-right: 10px;
    }
    .draggable-item {
      width: 100%;
    }
  }
  .file-item {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    padding: 10px 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
    border: 1px solid #f1f1f1;
    color: #989898;
    overflow: hidden;

    .darg-btn {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: move;
    }
    .delete-btn {
      width: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: pointer;
      &:hover {
        color: #ff3d3d;
      }
    }
    &:hover {
      // color: #fff;
      // background-color: hsl(15, 88%, 55%);
      border: 1px solid hsl(15, 88%, 55%);
      .darg-btn {
        color: #fff;
        background-color: hsl(15, 88%, 55%);
      }
    }
    .image-wrapper {
      box-sizing: border-box;
      padding: 4px;
      flex: 130px 0 0;
      height: 100%;
      border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 56px;
      overflow: hidden;
      color: #ff3d3d;
      align-self: start;
      background-color: #f7f5f5;
      a {
        color: inherit;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      ::v-deep {
        .el-image {
          background-color: transparent;
        }
        .el-image__inner {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .file-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
